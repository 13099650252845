const STORE_MODULE_NAME = 'test'

const actions = {
  fetchItem() {
    console.log('fetchItem')
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
