import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import storeModule from './storeModule'
import useStore from '@/utils/store'

export function useManageStore() {
  if (!store.hasModule(storeModule.STORE_MODULE_NAME)) {
    console.log('mounted')
    store.registerModule(storeModule.STORE_MODULE_NAME, storeModule)
  }

  onUnmounted(() => {
    if (store.hasModule(storeModule.STORE_MODULE_NAME)) {
      console.log('unmounted')
      store.unregisterModule(storeModule.STORE_MODULE_NAME)
    }
  })
}

export function useItem() {
  const { dispatch } = useStore(storeModule.STORE_MODULE_NAME)
  const fetchItem = () => {
    dispatch('fetchItem')
  }

  return {
    fetchItem,
  }
}

const visibleFirstComponent = ref(false)
export function useFirstComponent() {
  const turnOnFirstComponent = () => {
    visibleFirstComponent.value = true
  }

  const turnOffFirstComponent = () => {
    visibleFirstComponent.value = false
  }

  return {
    visibleFirstComponent,
    turnOnFirstComponent,
    turnOffFirstComponent,
  }
}
