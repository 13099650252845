<template>
  <div>
    test1
    <FirstComponent />
    <SecondComponent />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useItem, useManageStore } from './viewModel'
import FirstComponent from './components/FirstComponent.vue'
import SecondComponent from './components/SecondComponent'

export default defineComponent({
  components: {
    FirstComponent,
    SecondComponent,
  },
  setup() {
    useManageStore()
    const {
      fetchItem,
    } = useItem()
    fetchItem()
    return {
    }
  },
})
</script>
